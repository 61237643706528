<template>
  <v-row justify="center">
    <v-icon color="warning" class="mr-2" @click.stop="dialog = true">
      mdi-pencil
    </v-icon>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">{{ $t('ACTION.UPDATE') }}</v-card-title>

        <v-card-text>
          <v-autocomplete
            v-model="data.import_group.id"
            :items="getNomenklaturaGroup"
            dense
            outlined
            item-text="name"
            item-value="id"
            label="Nomenklatura Gruppa"
          ></v-autocomplete>

          <v-autocomplete
            v-model="data.import_category.id"
            :items="getNomenklatura"
            dense
            outlined
            item-text="name"
            item-value="id"
            label="Nomenklatura"
          ></v-autocomplete>
          <v-autocomplete
            v-model="data.product_condition.id"
            :items="getData"
            dense
            outlined
            item-text="name"
            item-value="id"
            label="Holati"
          ></v-autocomplete>

          <v-text-field label="Miqdori" outlined dense v-model="data.amount">
          </v-text-field>

          <v-text-field label="Narxi" outlined dense v-model="data.price">
          </v-text-field>

          <v-text-field label="Jami" outlined dense v-model="data.total">
          </v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            Yopish
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Yuborish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      data: {}
    }
  },
  created() {
    this.$store.dispatch('getProductCondition')
    this.$store.dispatch('getNomenklatura')
    this.$store.dispatch('getNomenklaturaGroup')

    this.data = Object.assign({}, this.index)
    this.data = JSON.parse(JSON.stringify(this.index))
  },
  computed: {
    getData() {
      return this.$store.state.requests.productCondition
    },
    getNomenklatura() {
      return this.$store.state.requests.allNomenklatureAll
    },
    getNomenklaturaGroup() {
      return this.$store.state.requests.nomenklaturaGroup
    }
  },
  props: {
    index: Object
  }
}
</script>
