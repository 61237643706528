<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              Asosiy Vosita Hujjat malumotlari o'zgartirish
            </h3>
          </div>
        </div>
        <div class="card-body">
          <div>
            <div class="d-flex flex-wrap">
              <div class="col-3">
                <label>Kontragent nomi</label>
                <v-autocomplete
                  dense
                  :items="contragentsList"
                  v-model="items.contragent"
                  @change="getContracts"
                  @blur="aa('contragent', items.contragent)"
                  v-debounce:400ms="myFn"
                  item-text="full_name"
                  :success="
                    contragent !== '' &&
                    contragent !== null &&
                    contragent !== undefined
                  "
                  item-value="id"
                  outlined
                ></v-autocomplete>
                <!-- </v-col> -->
              </div>

              <div class="col-3">
                <label>Kontragent kontrakt raqami</label>
                <v-autocomplete
                  dense
                  :items="contragent_contractLists"
                  v-model="items.contragent_contract"
                  @blur="aa('contragent_contract', items.contragent_contract)"
                  item-text="reg_number"
                  item-value="id"
                  outlined
                ></v-autocomplete>
              </div>

              <div class="col-3">
                <label>Sana</label>
                <!-- <v-text-field outlined dense v-model="items.oper_date">
                </v-text-field> -->
                <!-- <v-col cols="3" class="pl-0 py-0"> -->
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      v-model="items.oper_date"
                      @change="aa('oper_date', items.oper_date)"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @change="aa('oper_date', items.oper_date)"
                    v-model="items.oper_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
                <!-- </v-col> -->
              </div>

              <div class="col-3">
                <label>Reg raqami</label>
                <v-text-field
                  @blur="aa('reg_number', items.reg_number)"
                  outlined
                  dense
                  v-model="items.reg_number"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <label>Izoh</label>
                <v-text-field
                  @blur="aa('comment', items.comment)"
                  outlined
                  dense
                  v-model="items.comment"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <label>Manba</label>
                <v-select
                  dense
                  v-model="items.payment_source"
                  @blur="aa('payment_source', items.payment_source)"
                  :items="paymentSources"
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-select>
              </div>
              <div class="col-3">
                <label>Mas’ul </label>
                <v-autocomplete
                  dense
                  :items="allStuffs"
                  v-model="items.responsible_name"
                  v-debounce:400ms="myStaff"
                  @change="aa('responsible', items.responsible_name)"
                  item-text="full_name"
                  item-value="id"
                  outlined
                ></v-autocomplete>
                <!-- </v-text-field> -->
              </div>
            </div>
          </div>

          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="items.details"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <updateTable :index="item" />
            </template>
          </v-data-table>
        </div>
        <div class="card-action text-right">
          <v-btn color="primary" @click="save">Saqlash</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import updateTable from './updateTable'
export default {
  components: { updateTable },
  data() {
    return {
      items: {},
      allData: {},
      contragentsList: [],
      contragent_contractLists: [],
      headers: [
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Nomenklatura',
          value: 'import_category.name'
        },
        {
          text: 'Holati',
          value: 'product_condition.name'
        },
        {
          text: 'Miqdori',
          value: 'amount'
        },
        {
          text: 'Narxi',
          value: 'price'
        },
        {
          text: 'Jami',
          value: 'total'
        },
        {
          text: 'Nds',
          value: 'nds'
        },
        {
          text: 'Nds summa',
          value: 'nds_summa'
        },
        {
          text: 'Jami nds summa',
          value: 'total_withnds_summa'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('getPaymentSource')
    this.$store.dispatch('getAllStaff')
    this.$store.dispatch('mainAssetsTaken')
    this.$store.dispatch('mainAssetTakenDetails', this.$route.params.id)
  },
  computed: {
    allStuffs() {
      return this.$store.state.requests.allStaff
    },
    paymentSources() {
      return this.$store.state.requests.paymentSource
    },
    mainAssetsDocDetails() {
      return this.$store.state.requests.mainAssetTakenDetails
    }
  },
  watch: {
    mainAssetsDocDetails: function (val) {
      const data = {}
      for (let i in val) {
        data[i] = val[i]
      }
      const data2 = {
        full_name: data.contragent_name,
        id: data.contragent
      }
      this.myStaff(val.responsible_name)
      this.contragentsList.push(data2)
      this.getContracts(data2)
      this.items = data
    }
  },
  methods: {
    aa(objKey, value) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(value)) {
        this.allData[objKey] = value
        console.log(this.allData)
      }
    },
    myStaff(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('getStaffList', data)
    },
    myFn(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('counteragentsSearch', data).then((res) => {
        this.contragentsList = []
        this.contragentsList = [...res]
      })
    },
    getContracts(val) {
      if (val.id !== undefined) {
        this.$store.dispatch('counteragentContracts', val.id).then((res) => {
          this.contragent_contractLists = []
          this.contragent_contractLists = [...res]
        })
      } else {
        this.$store.dispatch('counteragentContracts', val).then((res) => {
          this.contragent_contractLists = []
          this.contragent_contractLists = [...res]
        })
      }
    },
    save() {
      this.$store.dispatch('mainAssetTakenUpdate', {
        id: this.$route.params.id,
        data: this.allData
      })
      this.allData = {}
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: ' Moliya' },
      { title: 'Asosiy Vositalar Hujjati' }
    ])
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
.card-action {
  padding: 2rem 2.25rem;
}
</style>
